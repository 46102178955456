@tailwind base;
@tailwind components;
@tailwind utilities;

/* add your global styles here; use tailwind classes for component/element styles */

@font-face {
    font-family: PixeloidSans;
    src: local('PixeloidSans'),
        url('../fonts/PixeloidSans.woff2') format('woff2'),
        url('../fonts/PixeloidSans.woff') format('woff');
}
